<template>
  <div>
    <DefaultRegister
      :title="title"
      :subTitle="subTitle"
      :narrative="narrative"
      :stepLineData="stepLineData"
      :stepNum="stepNum"
    >
      <template v-slot:content>
        <router-view :key="$route.fullPath" />
      </template>
    </DefaultRegister>
  </div>
</template>

<script>
import DefaultRegister from '@/components/template/register/Default';
import { registerFinanicalStep } from '@/util/register';

export default {
  name: 'bankIndx',
  components: { DefaultRegister },
  data() {
    return {
      stepLineData: [],
      routerMap: {
        financialInfo: 0,
        iDProof: 1,
        addressProof: 2
      }
    };
  },
  computed: {
    routerName() {
      return this.$route.name;
    },
    stepNum() {
      return registerFinanicalStep[this.routerName];
    },
    title() {
      return this.$t(`register.financialVerification.${this.routerName}.ver`);
    },
    subTitle() {
      return this.$t(`register.financialVerification.${this.routerName}.subTitle`);
    },
    narrative() {
      return this.$t(`register.financialVerification.${this.routerName}.narrative`).includes('.')
        ? ''
        : this.$t(`register.financialVerification.${this.routerName}.narrative`);
    }
  },
  mounted() {
    this.setData();
  },
  methods: {
    setData() {
      Object.entries(registerFinanicalStep).map(([key, val]) => {
        this.stepLineData.push({
          value: val,
          label: this.$t(`register.tabs.${key}`)
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
