<template>
  <ul class="step-line">
    <li v-for="item in data" :key="item.value" :class="{ 'is-active': item.value <= stepNum }">{{ item.label }}</li>
  </ul>
</template>
<script>
export default {
  name: 'stepLine',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    stepNum: {
      type: Number | String,
      default: 0
    }
  },
  computed: {
    calStepNum() {
      return Number(this.stepNum);
    }
  }
};
</script>

<style lang="scss" scoped>
.step-line {
  display: flex;
  width: 100%;
  margin-top: 24px;
  li {
    flex: 1;
    color: $pu-dark-gray;
    font-size: 14px;
    font-weight: bolder;
    text-align: center;
    word-wrap: break-word;
    padding-bottom: 8px;
    border-bottom: 3px solid $pu-dark-gray;
    margin: 0 2px;
    &.is-active {
      color: $future-blue;
      border-color: $future-blue;
    }
  }
}
@media (min-width: 768px) {
  .step-line {
    margin-top: 32px;
    li {
      font-size: 18px;
      margin: 0 8px;
    }
  }
}
</style>
